import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Footer extends Component {

    htmlCookieLink = () => {
      // Function is required to use dangerouslySetInnerHTML
      const htmlContent = '<a href="javascript:UC_UI.showSecondLayer();" class="grey-text text-lighten-3">Cookies</a>';
      return <div  className="grey-text text-lighten-3" dangerouslySetInnerHTML={{ __html: htmlContent }} />;
    };

    render() {
        return (
          <footer className="page-footer grey darken-3">
            <div className="container">

              <div className="row">
                <div className="col s12 l5">
                  <Link to="/"><h5 className="white-text">Immovestics</h5></Link>
                  <p className="grey-text lighten-4">Immobilien. Investment. Analytics.</p>
                  <p className="grey-text lighten-4">Wir unterstützen Sie bei Ihren Immobilieninvestments, angefangen beim ersten Objekt bis hin zum diversifizierten Immobilien Portfolio.</p>
                </div>
                <div className="col s4 l2 offset-l1 left-align">
                  <ul>
                    <li className="grey-text text-lighten-3"><Link to={"/partner/kontakt"} className="grey-text text-lighten-3">Partner</Link></li>
                    <li className="grey-text text-lighten-3"><Link to={"/newsletter/anmelden"} className="grey-text text-lighten-3">Newsletter</Link></li>
                    <li className="grey-text text-lighten-3"><Link to={"/kontakt"} className="grey-text text-lighten-3">Kontakt</Link></li>
                  </ul>
                </div>
                <div className="col s4 l2 center-align">
                  <ul>
                    <li className="grey-text text-lighten-3"><a href="https://www.instagram.com/immovestics" target="_blank" rel="noopener noreferrer" className="grey-text text-lighten-3">Instagram</a></li>
                    <li className="grey-text text-lighten-3"><a href="https://www.twitter.com/immovestics" target="_blank" rel="noopener noreferrer" className="grey-text text-lighten-3">Twitter</a></li>
                    <li className="grey-text text-lighten-3"><a href="https://www.facebook.com/immovestics" target="_blank" rel="noopener noreferrer" className="grey-text text-lighten-3">Facebook</a></li>
                  </ul>
                </div>
                <div className="col s4 l2 right-align">
                  <ul>
                    <li className="grey-text text-lighten-3"><Link to={"/datenschutz"}><span className="grey-text text-lighten-3">Datenschutz</span></Link></li>
                    <li className="grey-text text-lighten-3"><Link to={"/impressum"}><span className="grey-text text-lighten-3">Impressum</span></Link></li>
                    <li className="grey-text text-lighten-3">{this.htmlCookieLink()}</li>
                    <li className="grey-text text-lighten-3"><Link to={"/agb"}><span className="grey-text text-lighten-3">AGB</span></Link></li>
                  </ul>
                </div>
              </div>
              <div className="footer-copyright grey darken-3">
                <div className="container">
                  <div className="row">
                    <div className="col s12 center-align">
                      <span className="grey-text text-ligthen-3">
                        &copy; 2020-2025 Relatively Unique GmbH
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        )
    }
}

export default Footer;
