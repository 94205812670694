import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';

const HorizontalBarChart = ({ title, x_axis, tooltip_unit, rotate_x_axis, set0, set1, price_attr }) => {
  const graphRef = useRef(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    try {
      drawGraph();
    } catch (err) {
      console.error("Error in drawGraph:", err);
      setError(`Error drawing graph: ${err.message}`);
    }
  }, [title, x_axis, tooltip_unit, rotate_x_axis, set0, set1, price_attr]);

  const listOfBinaryFeatures = [
    ["rent_vs_balcony_or_terrace", "Balkon/Terrasse"],
    ["rent_vs_elevator", "Aufzug"],
    ["rent_vs_garden", "Garten"],
    ["rent_vs_guest_toilet", "Gästetoilette"],
    ["rent_vs_heritage_listed_object", "Denkmalgeschützt"],
    ["rent_vs_kitchen", "Küche"],
    ["rent_vs_pets", "Haustiere"],
    ["rent_vs_stepless_access", "Barrierefrei"],
    ["rent_vs_underfloor_heating", "Fußbodenheizung"],
    ["price_vs_balcony_or_terrace", "Balkon/Terrasse"],
    ["price_vs_elevator", "Aufzug"],
    ["price_vs_garden", "Garten"],
    ["price_vs_guest_toilet", "Gästetoilette"],
    ["price_vs_heritage_listed_object", "Denkmalgeschützt"],
    ["price_vs_kitchen", "Küche"],
    ["price_vs_pets", "Haustiere"],
    ["price_vs_stepless_access", "Barrierefrei"],
    ["price_vs_underfloor_heating", "Fußbodenheizung"],
  ];

  const drawGraph = () => {
    //console.log("Drawing graph with price_attr:", price_attr);
    //console.log("set1:", set1);

    if (!set1 || !set1["general"] || !set1["general"]["2025"] || !set1["general"]["2025"]["stats"]) {
      throw new Error("Invalid data structure in 2025");
    }

    const generalRent = set1["general"]["2025"]["stats"][price_attr];
    //console.log("generalRent:", generalRent);

    const data2 = listOfBinaryFeatures.reduce((acc, [key, name]) => {
      if (key in set1) {
        const val1 = set1[key]["2025"]["1"][price_attr];
        const diff = val1 - generalRent;
        const diff_rounded = ((Math.round(diff * 100)) / 100).toFixed(2);
        const count = set1[key]["2025"]["1"]["count"];
        if (count > 0 && diff_rounded > 0) {
          acc.push({
            name: name,
            value: parseFloat(diff_rounded),
            count: count,
          });
        }
      }
      return acc;
    }, []);

    //console.log("Processed data2:", data2);

    if (data2.length === 0) {
      throw new Error("No data to display");
    }

    data2.sort((a, b) => b.value - a.value);

    const svg = d3.select(graphRef.current);
    svg.selectAll('*').remove();

    const margin = { top: 20, right: 30, bottom: 40, left: 90 };
    const width = svg.node().getBoundingClientRect().width - margin.left - margin.right;
    const height = svg.node().getBoundingClientRect().height - margin.top - margin.bottom;

    const x = d3.scaleLinear()
      .domain([0, d3.max(data2, d => d.value)])
      .range([0, width]);

    const y = d3.scaleBand()
      .domain(data2.map(d => d.name))
      .range([0, height])
      .padding(0.1);

    const g = svg.append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    g.selectAll(".bar")
      .data(data2)
      .enter().append("rect")
      .attr("class", "bar")
      .attr("y", d => y(d.name))
      .attr("height", y.bandwidth())
      .attr("x", 0)
      .attr("width", d => x(d.value))
      .attr("fill", "steelblue");

    g.append("g")
      .attr("transform", `translate(0,${height})`)
      .call(d3.axisBottom(x));

    g.append("g")
      .call(d3.axisLeft(y));

    // ... rest of your drawing logic ...
  };

  const showTooltip = (x, y, content) => {
    const tooltip = document.getElementById('tooltip');
    tooltip.style.display = 'block';
    tooltip.style.left = `${x}px`;
    tooltip.style.top = `${y}px`;
    tooltip.innerText = content;
  };

  const hideTooltip = () => {
    const tooltip = document.getElementById('tooltip');
    tooltip.style.display = 'none';
  };

  const containerStyle = {
    width: '100%',
    height: 0,
    paddingBottom: '60%',
    position: 'relative',
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <h6 style={{ textAlign: 'center' }}>{title}</h6>
      <div style={containerStyle}>
        <svg
          ref={graphRef}
          width="100%"
          height="100%"
          style={{ position: 'absolute', top: 0, left: 0 }}
        />
      </div>
      <div id="tooltip" style={{ display: 'none', position: 'absolute', backgroundColor: 'rgba(0, 0, 0, 0.7)', color: '#fff', padding: '4px', zIndex: 1 }}></div>
      <div style={{ textAlign: 'center', marginBottom: '0px' }}>
        <div>{x_axis}</div>
      </div>
    </div>
  );
};

export default HorizontalBarChart;
