import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const CategoryGraph = ({ title, x_axis, tooltip_unit, rotate_x_axis, set0, set1, set2, set3, price_attr }) => {
  const graphRef = useRef(null);

  useEffect(() => {
    drawGraph();
  }, [title, x_axis, tooltip_unit, rotate_x_axis, set0, set1, set2, set3, price_attr]);

  const processData = (set) => {
    return Object.keys(set).map((key) => ({
      name: key,
      value: (Math.round(set[key][price_attr] * 100) / 100).toFixed(2),
      count: set[key]['count'],
    }));
  };

  const data1 = processData(set0);
  const data2 = processData(set1);
  const data3 = processData(set2);
  const data4 = processData(set3);

  const drawGraph = () => {
    const svg = d3.select(graphRef.current);

    // Clean up previous graphs
    svg.selectAll('*').remove();

    // Update the margin based on the container size
    const containerWidth = (graphRef.current?.parentElement?.offsetWidth) * 1.0 || 500;
    const containerHeight = containerWidth * 0.6; // You can adjust this aspect ratio as needed

    const marginBaseValue = containerWidth * 0.1;
    const marginTop = marginBaseValue * 0.1;
    const marginBottom = marginBaseValue * 1.2;
    const margin = { top: marginTop, right: marginBaseValue, bottom: marginBottom, left: marginBaseValue };

    // Derive width and height
    const width = containerWidth - margin.left - margin.right;
    const height = containerHeight - margin.top - margin.bottom;

    const allData = [...data1, ...data2, ...data3, ...data4];
    const x = d3.scaleBand()
      .range([0, width])
      .domain(allData.map(d => d.name))
      .padding(0.1);

    // Get the min and max values for the y-axis and ensure that these are used as numbers.
    // Otherwise, scaling of the y-axis will not work correctly.
    const minValue = d3.min(allData, d => parseFloat(d.value));
    const maxValue = d3.max(allData, d => parseFloat(d.value));
    const y = d3.scaleLinear()
      .range([height, 0])
      .domain([Math.min(0, minValue), Math.max(0, maxValue)]);
    //.domain([Math.min(0, d3.min(allData, d => d.value)), 1.0 * d3.max(allData, d => d.value)]);
    //.domain([0, d3.max(allData, d => d.value)]);

    const xAxis = d3.axisBottom(x);
    const yAxis = d3.axisLeft(y);

    svg.append('g')
      .attr('transform', `translate(${margin.left}, ${height + margin.top})`)
      .call(xAxis).selectAll('text')
      .style('text-anchor', 'end')
      .attr('dx', '-0.5em')
      .attr('dy', '0.5em')
      .attr('transform', `rotate(${rotate_x_axis})`); // Rotating x-axis ticks by 45 degrees


    svg.append('g')
      .attr('transform', `translate(${margin.left}, ${margin.top})`)
      .call(yAxis);


    // Draw bars for data1
    svg.selectAll('.bar1')
      .data(data1)
      .enter()
      .append('rect')
      .attr('class', 'bar1')
      .attr('x', d => x(d.name) + margin.left)
      .attr('y', d => y(d.value) + margin.top)
      .attr('width', x.bandwidth() / 4)
      .attr('height', d => height - y(d.value))
      .attr('fill', d => (d.count === 0 || d.count === null) ? 'rgba(173, 216, 230, 0.3)' : 'lightblue')
      .on('mouseover', (event, d) => {
        showTooltip(event.pageX, event.pageY, `${d.name}: ${d.value} ${tooltip_unit}\nAnzahl: ${d.count}`);
      })
      .on('mouseout', () => {
        hideTooltip();
      });

    // Draw bars for data2
    svg.selectAll('.bar2')
      .data(data2)
      .enter()
      .append('rect')
      .attr('class', 'bar2')
      .attr('x', d => x(d.name) + x.bandwidth() / 4 + margin.left)
      .attr('y', d => y(d.value) + margin.top)
      .attr('width', x.bandwidth() / 4)
      .attr('height', d => height - y(d.value))
      .attr('fill', d => (d.count === 0 || d.count === null) ? 'rgba(70, 130, 180, 0.3)' : 'steelblue')
      .on('mouseover', (event, d) => {
        showTooltip(event.pageX, event.pageY, `${d.name}: ${d.value} ${tooltip_unit}\nAnzahl: ${d.count}`);
      })
      .on('mouseout', () => {
        hideTooltip();
      });
  

    // Draw bars for data3
    svg.selectAll('.bar3')
      .data(data3)
      .enter()
      .append('rect')
      .attr('class', 'bar3')
      .attr('x', d => x(d.name) + x.bandwidth() * 2/4 + margin.left)
      .attr('y', d => y(d.value) + margin.top)
      .attr('width', x.bandwidth() / 4)
      .attr('height', d => height - y(d.value))
      .attr('fill', d => (d.count === 0 || d.count === null) ? 'rgba(59, 89, 152, 0.3)' : '#3B5998')
      .on('mouseover', (event, d) => {
        showTooltip(event.pageX, event.pageY, `${d.name}: ${d.value} ${tooltip_unit}\nAnzahl: ${d.count}`);
      })
      .on('mouseout', () => {
        hideTooltip();
      });

    // Add the fourth bar for data4
    svg.selectAll('.bar4')
      .data(data4)
      .enter()
      .append('rect')
      .attr('class', 'bar4')
      .attr('x', d => x(d.name) + x.bandwidth() * 3/4 + margin.left)
      .attr('y', d => y(d.value) + margin.top)
      .attr('width', x.bandwidth() / 4)
      .attr('height', d => height - y(d.value))
      .attr('fill', d => (d.count === 0 || d.count === null) ? 'rgba(25, 25, 112, 0.3)' : '#191970')
      .on('mouseover', (event, d) => {
        showTooltip(event.pageX, event.pageY, `${d.name}: ${d.value} ${tooltip_unit}\nAnzahl: ${d.count}`);
      })
      .on('mouseout', () => {
        hideTooltip();
      });

  };

  const showTooltip = (x, y, content) => {
    const tooltip = document.getElementById('tooltip');
    tooltip.style.display = 'block';
    tooltip.align = 'left';
    tooltip.style.left = `${x}px`;
    tooltip.style.top = `${y}px`;
    tooltip.innerText = content;
  };

  const hideTooltip = () => {
    const tooltip = document.getElementById('tooltip');
    tooltip.style.display = 'none';
  };

  const containerStyle = {
    width: '100%',
    height: 0,
    paddingBottom: '60%', // You can adjust this to control the aspect ratio of the SVG
    position: 'relative',
  };

  return (
    <div>
      <h6 style={{ textAlign: 'center' }}>{title}</h6>
      <div style={containerStyle}>
        <svg
          ref={graphRef}
          width="100%"
          height="100%"
          style={{ position: 'absolute', top: 0, left: 0 }}
        />
      </div>
      <div id="tooltip" style={{ display: 'none', position: 'absolute', backgroundColor: 'rgba(0, 0, 0, 0.7)', color: '#fff', padding: '4px', zIndex: 1 }}></div>
      <div style={{ textAlign: 'center', marginBottom: '0px' }}>
        <div>{x_axis}</div>
      </div>
      {/* Legend */}
      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px', width: '60%', margin: '0 auto' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginRight: '15px' }}>
          <div style={{ width: '10px', height: '10px', backgroundColor: 'lightblue', marginRight: '10px' }}></div>
          <span>2022</span>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginRight: '15px' }}>
          <div style={{ width: '10px', height: '10px', backgroundColor: 'steelblue', marginRight: '5px' }}></div>
          <span>2023</span>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginRight: '15px' }}>
          <div style={{ width: '10px', height: '10px', backgroundColor: '#3B5998', marginRight: '5px' }}></div>
          <span>2024</span>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ width: '10px', height: '10px', backgroundColor: '#191970', marginRight: '5px' }}></div>
          <span>2025</span>
        </div>
      </div>
    </div>
  );
};

export default CategoryGraph;
